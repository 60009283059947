<template>
  <v-dialog v-model="dialog" class="dialog" max-width="500" persistent>
    <v-card class="card text-center">
      <h3 class="card-text mb-8">{{ $t("edit_daily") }}</h3>
      <p class="mt-5 font-raleway-regular text-left">
        {{ $t("billing_page.billing_breakdown_description") }}
      </p>
      <div class="d-flex justify-space-between">
        <h3>${{ budgetAmount }}</h3>
        <div style="font-size: 20px" class="font-raleway-regular">
          <span>${{ getTotalAmountForWeek }}/{{ $t("create_campaign_page.week") }}</span>
        </div>
      </div>
      <v-slider
        v-model="budgetAmount"
        max="200"
        min="5"
        height="10"
        class="mt-4"
      ></v-slider>
      <div
        class="d-flex justify-space-between total-card-style align-center mt-5"
      >
        <div class="text-left">
          <h3 class="font-family-raleway-bold">{{ $t("create_campaign_page.total_forweek") }}</h3>
          <p class="font-raleway-regular mt-2 mb-0">
            {{ $t("create_campaign_page.will_be_charged") }}
            <strong>${{ getTotalAmountForWeek }}</strong> {{$t("create_campaign_page.first_week_ads")}}
          </p>
        </div>
        <h3>
          <strong>${{ getTotalAmountForWeek }}</strong>
        </h3>
      </div>
      <v-divider class="my-7" />
      <div>
        <div class="d-flex justify-space-between">
          <span class="font-raleway-regular">{{ $t("create_campaign_page.total_ad_spend") }}</span>
          <strong class="font-family-raleway-bold"
            >${{ getTotalAmountForWeek }}</strong
          >
        </div>
        <div class="d-flex justify-space-between my-3">
          <span class="font-raleway-regular">{{ $t("create_campaign_page.ad_spend_fee") }}</span>
          <strong class="font-family-raleway-bold"
            >${{ getAmountByFee }}</strong
          >
        </div>
        <div class="d-flex justify-space-between font-family-raleway-bold">
          <h3>{{ $t("create_campaign_page.total_charges") }}</h3>
          <h2 class="font-family-raleway-bold">${{ getAmountWithFee }}</h2>
        </div>
      </div>
      <v-row class="mt-5 mb-2">
        <v-col md="4">
          <v-btn
            style="background-color: white; box-shadow: none"
            class="font-family-raleway-medium text-decoration-underline mt-2 text-capitalize"
            @click="$emit('cancelModal')"
            >{{ $t("back_button") }}
          </v-btn>
        </v-col>
        <v-col md="8">
          <v-btn
            class="button font-weight-bold px-5"
            height="60"
            width="100%"
            @click="saveChanges"
            :loading="loading"
            >{{$t("profile_page.save_changes_button")}}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { campaignService } from "@/services/campaign";

export default {
  name: "EditBudget",
  props: {
    dialog: Boolean,
    campaignId: String,
    budgetAmountDefault: Number,
  },
  data() {
    return {
      budgetAmount: 0,
      loading: false,
    };
  },
  created() {
    this.budgetAmount = this.budgetAmountDefault;
  },
  methods: {
    async saveChanges() {
      this.loading = true;
      try {
        await campaignService.updateCampaignBudget(
          this.campaignId,
          this.budgetAmount
        );
        this.$notify({
          group: "foo",
          type: "success",
          title: "Successfully updated!",
        });
        this.$emit("cancelModal");
        this.$emit("getCampaignInfos");
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
  },
  computed: {
    getTotalAmountForWeek() {
      return this.budgetAmount * 7;
    },
    getAmountByFee() {
      return (this.getTotalAmountForWeek * 5) / 100;
    },
    getAmountWithFee() {
      return this.getTotalAmountForWeek + this.getAmountByFee;
    },
  },
};
</script>
